/**
 * A simple publish-subscribe manager. This object is a singleton.
 *
 * @class PubSubManager
 * @singleton
 */
window.PubSubManager = (function() {
	'use strict';
	const subscribers = {}

	return {
		/**
		 * Subscribe to a topic. If replay is true, all events published to this topic
		 * before the subscription will be replayed.
		 *
		 * @param  {String} topic    The topic to subscribe to.
		 * @param  {Function} callback The callback to call when an event is published to this topic.
		 * @param  {Boolean} replay   If true, all events published to this topic before the subscription
		 *                             will be replayed.
		 */
		subscribe: function(topic, callback, replay) {
			if (!subscribers[topic]) {
				subscribers[topic] = {
					callbacks: [],
					data: null
				};
			}

			subscribers[topic].callbacks.push(callback);

			if (replay && subscribers[topic].data) {
				callback(subscribers[topic].data);
			}
		},

		/**
		 * Unsubscribe from a topic.
		 *
		 * @param  {String} topic    The topic to unsubscribe from.
		 * @param  {Function} callback The callback to remove.
		 */
		unsubscribe: function(topic, callback) {
			if (subscribers[topic]) {
				const index = subscribers[topic].callbacks.indexOf(callback);
				if (index > -1) {
					subscribers[topic].callbacks.splice(index, 1);
				}
			}
		},

		/**
		 * Publish an event to a topic.
		 *
		 * @param  {String} topic The topic to publish to.
		 * @param  {*} data The data to publish.
		 */
		publish: function(topic, data) {
			const subscriber = subscribers[topic];
			if (subscriber) {
				subscriber.callbacks.forEach(callback => {
					callback(data);
				});
				subscriber.data = data;
			} else {
				subscribers[topic] = {
					callbacks: [],
					data
				};
			}
		},
	};
})();
